.dashboard-content-layout{
    padding: 20px;
    justify-content: space-between;
}
.dashboard-left-col-bottom-row{
    justify-content: space-between;
    padding-top: 15px;
}

/* general use css */
/* todo: responsive */
.dashboard-section-title{
    font-weight: 400;
    font-size: 16px;
}

/* Statistics Card */
.dashboard-statistics-card{
    /* height: 130px; */
    justify-content: space-between;
}
.dashboard-statistics-card-title{
    color: #8c8c8c;
    font-weight: 400;
    font-size: 16px;
}
.dashboard-statistics-card-contents{
    font-weight: 500;
    font-size: large;
}
.dashboard-statistics-card-redirects-text{
    font-weight: 400;
    color:rgba(0, 0, 0, 0.45);
    font-size: small;
}
.dashboard-statistics-card-footer-contents{
    color:rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: small;
    justify-content: space-between;
}

/* .dashboard-area-report-heading {
    margin-top: 24px;
} */

.dashboard-filter-btn {
    display: block;
    border: 1px solid #1890FF;
    color: #1890FF;
    margin-left: auto;
}

.dashboard-filter-btn:hover,
.dashboard-filter-btn:focus {
    color: #1890FF;
    border: 1px solid #1890FF;
}

.dashboard-title-on-filter{
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin: 0px 0px 20px;
}

.dashboard-stats-filter-layout {
    display: flex;
    justify-content: right;
    gap: 16px;
    margin-bottom: 20px;
}

.dashboard-new-subscribers-text{
    color: #52c41a;
}

.dashboard-unsubscribers-text {
    color: #ff4d4f;
}

.financial-performance-overview-contents{
    background-color: white;
    padding: 20px;
}
.financial-performance-header{
    justify-content: space-between;
}
.psl-distribution-header{
    justify-content: space-between;
}


.AR-statistics-contents{
    background-color: white;
    padding: 10px;
}
.AR-top-header{
    justify-content: space-between;
}
.AR-contents{
    font-size: 36px;
    font-weight: 400;
    justify-content: center;
}

.psl-distribution-contents{
    min-height: 530px;
    background-color: white;
    padding: 15px;
}

.todo-contents{
    min-height: 885px;
    margin-top: 15px;
    background-color: white;
    padding: 15px;
}
.todo-tab-title{
    padding-right: 5px;
}

.performance-history-top-statistics{
    min-height: 530px;
    background-color: white;
    padding: 15px;
}
.performance-history-statistics-contents{
    justify-content: center;
}

