/* Header */
.header-layout {
  padding: 0 0 0 20px !important;
  color: white !important;
  background: #002140 !important;
  height: 48px;
  line-height: 45px !important;
}
.header-layout .header-text {
  /* display: flex;
  justify-content: flex-end; */
  color: white !important;
}
.header-text > p {
  /* padding-right: 10px; */
  color: white !important;
}

/* Sider */
@media (max-width: 600px) {
  .ant-layout-sider{
    display: none;
  }
}

/* Menu */
.menu-layout {
  background-color: #fff;
  height: 100vh;
}
.menu-layout .ant-menu-item {
  margin-top: 0 !important;
}
.menu-layout .ant-menu-item::after {
  margin-top: 0 !important;
  border-right: 3px solid #2F3F54 !important;
}
.menu-layout .ant-menu-item-selected {
  background-color: rgba(47, 63, 84, 0.05) !important;
  color: #2F3F54;
}
.menu-layout .ant-menu-item-active {
  color: #2F3F54 !important;
}
.menu-layout .ant-menu-item a:hover {
  color: #2F3F54 !important;
}
.menu-layout .ant-menu-item-selected a {
  color: #2F3F54 !important;
}
.ant-menu-submenu-title:hover{
  color: #2F3F54;
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow{
  color: #2F3F54;
}
.ant-menu-submenu-selected{
  color: #2F3F54;
}

/* Breadcrumb */
.breadcrumb-layout {
  padding: 20px 0px;
  height: 100px;
  background-color: #FFFFFF; 
}
.with-tabs-breadcrumb-layout {
  padding: 20px 20px 0 20px;
  height: 176px;
  background-color: #FFFFFF; 
}
.breadcrumb-title {
  margin-top: 30px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

/* Content */
.content-layout {
  min-height: 93vh;
  background: #f9f9f9;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

/* Dropdown */
.ant-dropdown-menu  {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

/* Overrides */
.ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  height: 72px;
}