.login-layout{
  /* background-image: url('./../../assets/images/login-bg.png') !important; */
  background-color: #FAFAFA !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;

  display: flex;
  justify-content: center;
  align-items: center;
}

.login-wrapper {
  width: 100%;
  max-width: 658px;
}

.login-form__header {
  margin: 0;
  margin-bottom: 40px !important;
  font-weight: bold !important;
  text-align: center;
}

.login-card {
  max-width: 658px;
  width: 100%;
  margin: auto;
  background: rgba(255, 255, 255, 0.75);
  padding: 40px 0 80px;
}

.login-form {
  max-width: 428px;
  width: 100%;
  margin: auto;
}

.login-form__input {
  border: 0;
  border-bottom: 1px solid;
  background: transparent;
  border-radius: 0;
}

.login-form__input:focus, 
.login-form__input:hover {
  outline: 0;
  border-color: #000;
  box-shadow: none;
}

.login-form label {
  text-transform: uppercase;
  padding: 0 11px;
}

.login-form label span {
  text-transform: none;
}

.login-form input {
  font-size: 16px;
  font-weight: 500;
}

.login-form__button {
  height: 51px;
  background-color: #002140;
  border: 0;
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
}

.login-form__button:hover {
  background-color: #000e1c;
  border: 0;
}

.login-form__button span {
  font-size: 16px;
  font-weight: 700;
}

.login-form__remember-me span,
.login-form__forgot {
  font-size: 16px;
  color: #595959;
}

.login-secure{
  width: 100%;
  height: 19px;
  color: #595959;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin: 30px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.login-secure__icon{
  position: absolute;
  left: 7.98%;
  right: 7.98%;
  top: 3.12%;
  bottom: 3.12%;
}
