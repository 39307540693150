.address-field {
  margin-bottom: 10px;
}

.psl-management-header {
  background-color: #ffff;
  padding: 20px 20px 0 20px;
}

.psl-management-header-card{
  justify-content: space-between;
}

.general-av-action-col {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.psl-management-title-on-filter {
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin: 0px 20px;
}

.filter-btn {
  margin: 8px 0 32px;
  float: right;
}