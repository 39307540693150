.ant-table-thead > tr > th {
  background: #F0F0F0;
}

a.ant-typography {
  color: white !important;
}
.site-card-wrapper {
  margin-bottom: 24px;
} 

.ant-list-item-meta-title > a:hover{
  color: #2F3F54;
}

.ant-checkbox-checked .ant-checkbox-inner{
  background-color: #2F3F54;
  border-color: #2F3F54;
}
.ant-checkbox:hover{
  border-color: #2F3F54;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
  border-color: #2F3F54;
}

html{
  scroll-behavior: smooth;
}

.header-section-search-bar-section{
  display: flex;
  flex-direction: column;
}

.ant-card-body {
  padding: 20px 16px;
}

.ant-card-body h1 {
  font-size: 24px;
}